.dashboard {
  display: grid;
  grid-auto-flow: row;
  padding: 1rem;
}

.dashboard_charts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-top: 1rem;
  gap: 0.5rem;
  align-items: center;
}

.dashboard_chart {
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dashboard_chart_title {
  text-align: center;
  padding: 0.5rem;
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.lastValue {
  text-align: center;
  font-size: 1.5rem;
}

.section-title {
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
