.top_bar {
  display: grid;
  position: sticky;
  top: 0;
  background-color: #282c34;
  font-size: 2rem;
  z-index: 1;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.top_bar_brand {
  display: flex;
  gap: 2rem;
  margin-top: 0.5rem;
}

.top_bar_title {
  text-align: left;
}

.company {
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 16px;
}

.logo {
  width: 32px;
  height: 32px;
}

.flag {
  width: 24px;
  margin-right: 0.5rem;
}

.small-text {
  font-size: 0.8rem;
}
