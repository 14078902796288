.App {
  display:grid;
  grid-template-rows: auto 1fr;
  background-color: #282c34;
  min-height: 100vh; 
  color: white;
}

.link {
  color: #61dafb;
}
